import React from 'react';
import { Route, Routes } from 'react-router-dom';

import DashboardPage from '../../pages/dashboard';
import LoginPage from '../../pages/login';

const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<LoginPage />} />
      <Route path='dashboard' element={<DashboardPage />} />
    </Routes>
  );
};

export default Router;
