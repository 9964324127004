import './styles.css';

import { Button } from '@mui/material';
import React from 'react';

const DashButton = ({
  buttonText,
  onClick,
  variant,
  disabled,
  endIcon,
  startIcon,
  wrapperProps,
  ...buttonProps
}) => {
  return (
    <Button
      className='button'
      disabled={disabled}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      sx={{ ...wrapperProps }}
      {...buttonProps}
    >
      <span className='buttonText'>{buttonText}</span>
    </Button>
  );
};

export default DashButton;
