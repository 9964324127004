import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import * as React from 'react';

const DropdownMenu = ({ sorting, setSorting }) => {
  const handleChange = (event) => {
    setSorting(event.target.value);
  };

  return (
    <div className='dropdownMenuWrapper'>
      <span className='dropdownMenuLabel'>Сортировать по:</span>
      <FormControl>
        <Select
          className='dropdownMenu'
          value={sorting}
          onChange={handleChange}
          displayEmpty
          inputProps={{ 'aria-label': 'Without label' }}
        >
          <MenuItem value={'name'} onClick={() => setSorting('name')}>
            Имени
          </MenuItem>
          <MenuItem value={'created_at'} onClick={() => setSorting('created_at')}>
            Времени регистрации
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default DropdownMenu;
