import './styles.css';

import { Box } from '@mui/material';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { AuthQuery } from '../../api/queries';
import DashButton from '../../components/button';
import TextField from '../../components/text-field';
import { loginSchema } from '../../validation/schemas/login.schema';

const LoginPage = () => {
  const initialValues = { email: '', password: '' };
  const navigate = useNavigate();

  return (
    <Box className='loginPageWrapper'>
      <Formik
        initialValues={initialValues}
        validationSchema={loginSchema}
        onSubmit={async (values, actions) => {
          AuthQuery.SignIn(values.email, values.password)
            .then((response) => {
              if (response?.status === 200 || response?.status === 201) {
                sessionStorage.setItem('SacraliumDashboardAccesToken', response.data.accessToken);
                sessionStorage.setItem(
                  'SacraliumDashboardRefreshToken',
                  response.data.refreshToken,
                );
                navigate('/dashboard');
              }
            })
            .catch((error) => {
              if (error?.response?.status === 401) {
                actions.setFieldError('email', 'Вы ввели некорректные данные для авторизации');
              } else {
                actions.setFieldError('email', 'Непредвиденная ошибка');
              }
            });
        }}
      >
        {() => (
          <Box className='loginBlockWrapper'>
            <Form className='formWrapper'>
              <span className='loginText'>Авторизация</span>
              <span className='labelText'>Email:</span>
              <TextField
                fieldType='text'
                fieldName='email'
                wrapperProps={{ marginBottom: '1vw' }}
              />
              <span className='labelText'>Пароль:</span>
              <TextField
                fieldType='password'
                fieldName='password'
                wrapperProps={{ marginBottom: '1vw' }}
              />
              <DashButton variant='contained' buttonText='Вход' type='submit' />
            </Form>
          </Box>
        )}
      </Formik>
    </Box>
  );
};

export default LoginPage;
