/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
import axios from 'axios';

import { AuthQuery } from './queries';

export const errorCatch = (error) => {
  return error.response && error.response.data
    ? typeof error.response.data.message === 'object'
      ? error.response.data.message[0]
      : error.response.data.message
    : error.message;
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

instance.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${sessionStorage.getItem('SacraliumDashboardAccesToken')}`;
  return config;
});

instance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await AuthQuery.RefreshToken(
          sessionStorage.getItem('SacraliumDashboardRefreshToken'),
        );
        sessionStorage.setItem('SacraliumDashboardAccesToken', response?.accessToken);
        return instance.request(originalRequest);
        // eslint-disable-next-line no-shadow
      } catch (error) {
        if (errorCatch(error) === 'InvalidAccessToken') {
          sessionStorage.removeItem('SacraliumDashboardAccesToken');
          sessionStorage.removeItem('SacraliumDashboardRefreshToken');
        }
      }
    }

    throw error;
  },
);

export default instance;
