/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */

import instance from '..';

export const AuthQuery = {
  async SignIn(email, password) {
    return instance
      .post('/admin/auth/authenticate', {
        email,
        password,
      })
      .then((response) => response);
  },

  async RefreshToken(refreshToken) {
    return instance
      .post('/admin/auth/refresh-tokens', {
        refreshToken,
      })
      .then((response) => response.data);
  },
};
