import React from 'react';

const LogoutIcon = ({ onClick }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='1.75vw'
      height='1.75vw'
      fill='#fff'
      version='1.1'
      viewBox='0 0 489.9 489.9'
      xmlSpace='preserve'
      cursor='pointer'
      onClick={onClick}
    >
      <path d='M468.3 255.8l.2-.2c.3-.4.6-.7.8-1.1.1-.1.1-.2.2-.3.2-.4.5-.8.7-1.2 0-.1.1-.2.1-.2.2-.4.4-.8.6-1.3 0-.1 0-.1.1-.2.2-.4.3-.9.5-1.4 0-.1 0-.2.1-.2.1-.5.3-.9.3-1.4 0-.2 0-.3.1-.5.1-.4.1-.8.2-1.2.1-.6.1-1.1.1-1.7 0-.6 0-1.1-.1-1.7 0-.4-.1-.8-.2-1.2 0-.2 0-.3-.1-.5-.1-.5-.2-.9-.3-1.4 0-.1 0-.2-.1-.2-.1-.5-.3-.9-.5-1.4 0-.1 0-.1-.1-.2-.2-.4-.4-.9-.6-1.3 0-.1-.1-.2-.1-.2-.2-.4-.4-.8-.7-1.2-.1-.1-.1-.2-.2-.3-.3-.4-.5-.8-.8-1.1l-.2-.2c-.4-.4-.7-.9-1.2-1.3l-98.9-98.8c-6.7-6.7-17.6-6.7-24.3 0-6.7 6.7-6.7 17.6 0 24.3l69.6 69.6H136.8c-9.5 0-17.2 7.7-17.2 17.1 0 9.5 7.7 17.2 17.2 17.2h276.8l-69.1 69.1c-6.7 6.7-6.7 17.6 0 24.3 3.3 3.3 7.7 5 12.1 5s8.8-1.7 12.1-5l98.3-98.3c.5-.6.9-1 1.3-1.4z'></path>
      <path d='M110.7 34.3h128c9.5 0 17.2-7.7 17.2-17.1 0-9.5-7.7-17.2-17.2-17.2h-128C59.4 0 17.6 41.8 17.6 93.1v303.7c0 51.3 41.8 93.1 93.1 93.1h125.9c9.5 0 17.2-7.7 17.2-17.1 0-9.5-7.7-17.2-17.2-17.2H110.7c-32.4 0-58.8-26.4-58.8-58.8V93.1c.1-32.5 26.4-58.8 58.8-58.8z'></path>
    </svg>
  );
};

export default LogoutIcon;
