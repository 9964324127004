/* eslint-disable import/no-cycle */
import instance from '..';

export const GetLeadsQuery = {
  async GetLeads(searchString, offset, limit, sorting) {
    return instance
      .get(`/lead?text=${searchString}&offset=${offset}&limit=${limit}&order=${sorting}`)
      .then((response) => response);
  },
};
