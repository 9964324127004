import './styles.css';

import React from 'react';

import SearchIcon from '../../assets/icons/SearchIcon';

const SearchBar = ({ labelText, searchString, setSearchString, wrapperProps }) => {
  const handleChangeField = (value) => {
    setSearchString(value);
  };

  return (
    <div className='searchBarWrpr' style={wrapperProps}>
      <div className='searchWrapper'>
        <input
          className='searchField'
          value={searchString}
          placeholder={labelText}
          onChange={(input) => handleChangeField(input?.target?.value)}
        />
        <div className='searchIconWrapper'>
          <SearchIcon />
        </div>
      </div>
    </div>
  );
};
export default SearchBar;
