/* eslint-disable no-unsafe-optional-chaining */
import './styles.css';

import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { TableHead } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';

const TablePaginationActions = (props) => {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

function createData(id, email, name, createdAt) {
  return { id, email, name, createdAt };
}

const CustomTable = ({ page, setPage, rowsPerPage, setRowsPerPage, tableData }) => {
  const handleProcessTableData = () => {
    const result = [];
    tableData?.data?.leads?.map((item) => {
      result?.push(
        createData(item?.id, item?.email, item?.name, new Date(item?.createdAt).toLocaleString()),
      );
      return null;
    });
    return result;
  };
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData?.data?.count) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTableContent = () => {
    if (tableData?.data?.error) {
      return <div className='errorText'>Ошибка при загрузке</div>;
    }
    if (!tableData?.data?.count) {
      return <div className='missingResultsText'>Результаты не найдены</div>;
    }
    if (tableData?.data?.count) {
      return (
        <>
          <TableHead>
            <TableRow>
              <TableCell className='tableCell'>№</TableCell>
              <TableCell align='left' className='tableCell'>
                ID
              </TableCell>
              <TableCell align='left' className='tableCell'>
                Email
              </TableCell>
              <TableCell align='left' className='tableCell'>
                Имя персонажа
              </TableCell>
              <TableCell align='right' className='tableCell'>
                Время регистрации
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {handleProcessTableData()?.map((row, idx) => (
              <TableRow key={idx}>
                <TableCell style={{ width: 300 }} align='left' className='tableCell'>
                  {idx + 1 + page * rowsPerPage}
                </TableCell>
                <TableCell style={{ width: 650 }} align='left' className='tableCell'>
                  {row.id}
                </TableCell>
                <TableCell style={{ width: 500 }} align='left' className='tableCell'>
                  {row.email}
                </TableCell>
                <TableCell style={{ width: 500 }} align='left' className='tableCell'>
                  {row.name}
                </TableCell>
                <TableCell style={{ width: 300 }} align='right' className='tableCell'>
                  {row.createdAt?.toLocaleString()}
                </TableCell>
              </TableRow>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow className='tablePagination'>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                colSpan={4}
                count={tableData?.data?.count}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                labelRowsPerPage={'Строк на страницу:'}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </>
      );
    }
    return null;
  };

  return (
    <TableContainer component={Paper} className='tableContainer'>
      <Table sx={{ minWidth: 500 }} aria-label='custom pagination table' className='tableBody'>
        {renderTableContent()}
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
