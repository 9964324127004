import './styles.css';

import { Box, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import LogoutIcon from '../../assets/icons/LogoutIcon';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleLogout = () => {
    sessionStorage.removeItem('SacraliumDashboardAccesToken');
    sessionStorage.removeItem('SacraliumDashboardRefreshToken');
    navigate('/');
  };

  return (
    <Box className='headerWrapper'>
      <Typography className='headerTitle'>Sacralium Dashboard</Typography>
      {location.pathname === '/' ? null : <LogoutIcon onClick={() => handleLogout()} />}
    </Box>
  );
};

export default Header;
