/* eslint-disable no-nested-ternary */
import './styles.css';

import { Field } from 'formik';
import React from 'react';

import FaSolidEye from '../../assets/icons/FaSolidEye';
import SearchIcon from '../../assets/icons/SearchIcon';

const TextField = ({ fieldName, fieldType, labelText, isSearchBar, wrapperProps }) => {
  const [inputType, setInputType] = React.useState(fieldType);

  const handleChangeInputType = () => {
    if (inputType === 'text') {
      setInputType('password');
    }
    if (inputType === 'password') {
      setInputType('text');
    }
  };

  return (
    <Field name={fieldName} type={inputType}>
      {({ field, meta }) => (
        <div className='textFieldWrapper' style={wrapperProps}>
          <label className='labelText'>{labelText}</label>
          <div className='functionalityWrapper'>
            <div className='inputWrapper'>
              <input {...field} className='inputField' type={inputType} />
              <div className='iconWrapper' onClick={() => handleChangeInputType()}>
                {fieldType === 'password' ? <FaSolidEye /> : isSearchBar ? <SearchIcon /> : null}
              </div>
            </div>
            {meta?.error ? <span className='errorText'>{meta.error}</span> : null}
          </div>
        </div>
      )}
    </Field>
  );
};
export default TextField;
