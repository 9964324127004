/* eslint-disable no-underscore-dangle */
import './styles.css';

import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { GetLeadsQuery } from '../../api/queries';
import SearchBar from '../../components/searchbar';
import DropdownMenu from './components/dropdown-menu';
import CustomTable from './components/table';

const DashboardPage = () => {
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [searchString, setSearchString] = React.useState('');
  const [sorting, setSorting] = React.useState('created_at');
  const [tableData, setTableData] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!sessionStorage.getItem('SacraliumDashboardAccesToken')) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting, window?.document, searchString, page]);

  const handleGetLeads = async () => {
    GetLeadsQuery.GetLeads(searchString, rowsPerPage * page, rowsPerPage, sorting)
      .then((response) => {
        if (response.status === 200) {
          setTableData(response);
        }
      })
      .catch((error) => {
        setTableData(error.response);
        // eslint-disable-next-line no-console
        console.log(error);
      });
  };

  React.useEffect(() => {
    handleGetLeads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString, sorting, rowsPerPage, page, setPage]);

  return (
    <Box className='dashboardWrapper'>
      <Box className='searchBarWrapper'>
        <SearchBar
          labelText='Поиск'
          searchString={searchString}
          setSearchString={setSearchString}
        />
        <DropdownMenu sorting={sorting} setSorting={setSorting} />
      </Box>
      <CustomTable
        setRowsPerPage={setRowsPerPage}
        rowsPerPage={rowsPerPage}
        tableData={tableData}
        setPage={setPage}
        sorting={sorting}
        page={page}
      />
    </Box>
  );
};

export default DashboardPage;
